import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { observer } from "mobx-react";
import styles from "./style.module.scss";
import { useRequest } from "ahooks";
import { GetAreaStaticData } from "@/services/api";
import imgUrl from "@/assets/img/resource/area_total_bg.png";
import tipleft from "@/assets/img/tipleft.png";
import tipright from "@/assets/img/tipright.png";
import { objValueToInt } from "@/utils/parse";

import { useInterval } from 'ahooks';
const AreaBox: React.FC<{
  cityCode?: string;
  areaCode?: string;
  ZoomLevel: number;
}> = ({ ZoomLevel, areaCode, cityCode }) => {
  const { data: areaDataList = [], run: getAreaStaticDataRequest } = useRequest(
    useCallback(() => {
      return GetAreaStaticData("510500");
    }, []),
    {
      formatResult: (res) => {
        let retrunval = res.data ? res.data : [];

        let changedata: any[] = retrunval;
        let a = changedata.pop();
        changedata.unshift(a);
        return res.data ? changedata : [];
      },
        refreshDeps: []
    }
  );
 
  
  const intValueData = objValueToInt(areaDataList); // 将值转成整数
  const showAreaDataList = [
    {
      areaName: "道路收入",
      // berthTotal: 3251,
      incomeTotal: 62740,
      // roadTotal: 369852,
      // parkTotal: 369852,
      // carport:8043,
      // berthTotal:8043,
      // monthIncome:0,
      dayIncome:2978
    
    },
    {
      areaName: "江阳区",
      // berthTotal: 3251,
      incomeTotal: 26233388,
      // roadTotal: 369852,
      // parkTotal: 369852,
      carport: 6850,
      berthTotal: 6850,
      monthIncome: 0,
      dayIncome: 0
    },
    {
      areaName: "车场收入",
      // berthTotal: 3251,
      incomeTotal: 56980,
      // roadTotal: 369852,
      // parkTotal: 369852,
      // carport: 620,
      // berthTotal: 620,
      // monthIncome: 0,
      dayIncome: 2797
    },
    // {
    //   areaName: "合江县",
    //   // berthTotal: 3251,
    //   incomeTotal: 3013448,
    //   // roadTotal: 369852,
    //   // parkTotal: 369852,
    //   carport: 2012,
    //   berthTotal: 2012,
    //   monthIncome: 0,
    //   dayIncome: 0
    // },
  ];
  // let datalist = areaDataList.length > 0 ? intValueData : showAreaDataList;
  let datalist = showAreaDataList;
  console.log(datalist, "areaDataList==================================");
  useInterval(() => {
    getAreaStaticDataRequest()
  }, 180000)

  return (
    <div className={`${styles.data_box}`}>
      {datalist.map((item: object, index: number) => (
        <div className={styles.data_box_item} key={`${index}`}>
          {index == 0 || index ==4 ? (
            <div className={styles.item_piot}>
              <img src={tipright} alt="" />
            </div>
          ) : (
            <div className={styles.item_piot}>
              <img src={tipleft} alt="" />
            </div>
          )}
          {index == 3 ? (
            <div className={styles.item_title}>
              <i></i>
              {item["areaName"]}{" "}
            </div>
          ) : (
            <div className={styles.item_title}>
              {item["areaName"]} <i></i>
            </div>
          )}
          <div className={styles.item_content}>
            {/* <div className={styles.item_box}>
              <div className={styles.item_data_text}>总收入(元)：</div>
              <div className={styles.data_tamt}>{item["incomeTotal"] || 0}</div>
            </div> */}
            {/* <div className={styles.item_box}>
              <div className={styles.item_data_text}>经营车位(个)：</div>
              <div className={styles.data_tamt}>{item["berthTotal"] || 0}</div>
            </div>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>本月收入(元)：</div>
              <div className={styles.data_tamt}>{item["monthIncome"] || 0}</div>
            </div> */}
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>今日收入(元)：</div>
              <div className={styles.data_tamt}>{item["dayIncome"] || 0}</div>
            </div>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>本月收入(元)：</div>
              <div className={styles.data_tamt}>{item["incomeTotal"] || 0}</div>
            </div>
            {/* <div className={styles.item_box}>
              <div className={styles.item_data_text}>总车位(个)：</div>
              <div className={styles.data_tamt}>{item["berthTotal"] || 0}</div>
            </div>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>道路车位(个)：</div>
              <div className={styles.data_tamt}>{item["roadTotal"] || 0}</div>
            </div>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>路外车位(个)：</div>
              <div className={styles.data_tamt}>{item["parkTotal"] || 0}</div>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(AreaBox);
