import React, { useCallback, useEffect, useRef,useMemo, useState } from 'react';
import AlarmModal, { OnAlarmClick, AlarmType } from '@/components/AlarmModal';
import { useRequest, useInterval } from 'ahooks';
import { GetParkRank,GetRoadRank } from '@/services/api'
import Panel from '@/components/Panel/index'
import TopTale from '@/components/topTable';
import ImgSource from '@/assets/img/resource/zhouzhuan2.png'
import Abutton from '@/components/Abutton'
import { Spin } from 'antd'
import style from './style.module.scss'
import CustomTable from '@/components/CustomTable';

/** 停车场跳转地址 */
const iframeURL ="http://lz.dgjx0769.com/lz/#/UrbanIntegration/BasicDataManage/ParkManage/ParkManageDetail?id=1397828780990533114&parkName=%E8%A5%BF%E5%8D%97%E5%8C%BB%E7%A7%91%E5%A4%A7%E9%99%84%E5%B1%9E%E4%B8%AD5%E5%81%9C%E8%BD%A6%";
export interface ParkingTurnoverTableProps {
  areaCode?: string;
  sendIframeMessage: (params: {
    type: string;
    data: any;
  }) => void;
  onOpenWorkbill: () => void;
  onAlarmFocus: (roadSectionId: string,parkingNo: string) => void;
}
const $ = (selector: string) => document.querySelector(selector);
const ParkingTurnoverTable:React.FC<ParkingTurnoverTableProps> = props => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);
  const [alarmModal,setAlarmModal] = useState(false);
  const [changModal,setChangModal] = useState(true);
  const [tabNoModal,setTabNoModal] = useState(0);
  
  const alarmRef = useRef<{
    onAlarmClick: OnAlarmClick
  }>(null);
console.log(props.areaCode)
const { data , loading, error, refresh } = useRequest(useCallback(() => {
    return GetParkRank({
      areaCode:props.areaCode,
      limit:15,
    })
  }, [props.areaCode]), {
   
    formatResult: res => {
      if(res.data){
      // eslint-disable-next-line array-callback-return
      let data= res.data.filter((item:object,index:number)=>{
        if(index<15){
          item['key']=index
           return item
         }
       })
       return data
      }
      return res.data
    },
    refreshDeps: [props.areaCode]
  })
const { data: roaddata=[]} = useRequest(useCallback(() => {
        return GetRoadRank({
          areaCode:props.areaCode,
          limit:15,
        })
    }, [props.areaCode]), {
      
        formatResult: res => {
          if(res.data){
            // eslint-disable-next-line array-callback-return
            let data= res.data.filter((item:object,index:number)=>{
              if(index<15){
                item['key']=index
                 return item
               }
             })
             return data
            }
            return res.data
        },
        refreshDeps: [props.areaCode]
  })  
  

  const AbuttonBox = useMemo(() => {
    return <Abutton  iframeURL={iframeURL} text={''}/>
  }, [])

  const scrollContainer = useCallback(() => {
    const container = $('.parking-turnover-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
   setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
   // refresh();
  },allowRefresh ? 60000 : null)
let showData =data
let datas=changModal?roaddata:showData
  useEffect(() => {
    const container = $('.parking-turnover-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver&&datas) {
        setScrollInterval(100)
      }
    }
  }, [datas])

  const showIframe = () => {
    setAlarmModal(false);
    props.onOpenWorkbill();
  }

  const showAlarm = (alarmType: number) => {
    setAlarmModal(true);
    alarmRef.current?.onAlarmClick(alarmType as AlarmType);
  }

//   if (loading) {
//     return (
//       <Panel title="停车场/停车位周转率排行榜1111111"  >
//         <div className="fetch_container" style={{ height: 240 }}>
//           <Spin/>
//         </div>
//       </Panel>
//     )
//   }

//   if (error) {
//     return (
//       <Panel title="停车场/停车位周转率排行榜11111111111"   >
//         <div className="fetch_container" style={{ height: 240 }}>
//           请求错误
//         </div>
//       </Panel>
//     )
//   }

const data1 = [
  {
    areaName:'南昌区域',
    roadSectionName:'乐祖路',
    validTurnover:'2.8',
    income:'545'
  },
  {
    areaName:'南昌区域',
    roadSectionName:'招贤大道',
    validTurnover:'1.9',
    income:'445'
  },
  {
    areaName:'南昌区域',
    roadSectionName:'碧桂街',
    validTurnover:'1.6',
    income:'324'
  },
  {
    areaName:'南昌区域',
    roadSectionName:'银杏路',
    validTurnover:'1.4',
    income:'225'
  },
  {
    areaName:'南昌区域',
    roadSectionName:'翠岩路',
    validTurnover:'1.3',
    income:'187'
  },
]//道路
const data2 = [
  {
    areaName:'南昌区域',
    parkName:'竹海明珠房车营地',
    validTurnover:'2.8',
    income:'178'
  },
  {
    areaName:'南昌区域',
    parkName:'狮子峰景区停车场',
    validTurnover:'1.8',
    income:'98'
  },
  {
    areaName:'南昌区域',
    parkName:'神龙潭景区停车场',
    validTurnover:'1.5',
    income:'77'
  },
  {
    areaName:'南昌区域',
    parkName:'湾里旅游集散中心停车场',
    validTurnover:'1.3',
    income:'145'
  },
  {
    areaName:'南昌区域',
    parkName:'磨盘山公园西停车场',
    validTurnover:'2.5',
    income:'178'
  },
]//车场
const changdata =  (val: boolean)=> {
  setChangModal(val);
  setTabNoModal(val?1:0)
}
  return (
    <Panel
      title={changModal?'车场停车位周转率':'道路停车位周转率'}
    
      // renderLeft={AbuttonBox}
    >
       <div className={style.parkinglotttag} onClick={() => changdata(!changModal)}> 切换</div>
       <div className={style.parkinglotmore}  onClick={()=>showAlarm(7)}> 更多</div>
      <div onMouseEnter={stopTick} onMouseLeave={startTick}>
        <TopTale
          className="parking-turnover-table"
          dataSource={changModal ? data2 : data1}
          style={{height: '196px'}}
          rowKey="key"
          columns={[
            {
              title: '排行',
              render:(text,record,index)=>`TOP${index+1}`,
              },
            { title: '区域' , dataIndex: 'areaName'},
            { title:  changModal?'停车场名称':'路段名称', dataIndex: changModal?'parkName':'roadSectionName'},
            { title: '有效周转率',  dataIndex: 'validTurnover' },
            // { title: '总周转率',  dataIndex: 'turnover' },
            { title: '应收', dataIndex: 'income'}
          ]}
           scroll={{y: 156}}
        />
      </div>
      <AlarmModal
        areaCode={props.areaCode}
        ref={alarmRef}
        visible={alarmModal}
        onShowIframe={showIframe}
        onClose={() => setAlarmModal(false)}
        onAlarmFocus={props.onAlarmFocus}
        tabNo={tabNoModal}
      />
    </Panel>
  )
}

export default ParkingTurnoverTable;