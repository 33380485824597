/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useJurisdictionShowMenu } from "./utils";

const MENUS = ["停车资源", "停车经营", "停车监管", "停车难治理", "云坐席服务"];
const iframeURL = "https://web.lzzhtc.com/cloud-table/#/monitoringSeat";
const iframeURLtest = "https://web.dgjx0769.com/cloud-table/#/";

interface IBottomMenuType {
  onMenuChange: (val: number) => void;
  onOpenService: () => void;
  menu: number;
}

const BottomMenu: React.FC<IBottomMenuType> = (props) => {
  const [hiddenTab, setHiddenTab] = useState(false)

  const menuChange = (index: number) => props.onMenuChange(index);

  let newMenus = useJurisdictionShowMenu(); // 根据权限获取menus
  !newMenus.length && (newMenus = MENUS);

  // useEffect(() => {
  //   const firstMenuName = newMenus[0]
  //   if(newMenus.length === 1 && firstMenuName === '停车资源') {
  //     setHiddenTab(true)
  //   } else {
  //     // 如果当前第一个不是停车资源的话 那么就跳转到对应的页面
  //     menuChange(MENUS.findIndex(menu => menu === firstMenuName))
  //   }

  // }, [newMenus])

  return (
    <div style={{
      display: hiddenTab ? 'none' : 'block'
    }} className={styles.bottom_menu}>
      {newMenus.map((item, index) => {
        // 如果是云坐席就跳过遍历显示
        if (item === "云坐席服务") {
          return;
        }
        return (
          <button
            key={index}
            className={`${styles.menu_item} ${
              props.menu === index ? styles.menu_item_selected : ""
            }`}
            onClick={() => menuChange(index)}
          >
            {item}
          </button>
        );
      })}
      {/* 如果menus有云坐席才显示 */}
      {newMenus.includes("云坐席服务") && (
        <button className={styles.menu_item}>
          <a href={iframeURL} target="_blank">
            云坐席服务
          </a>
        </button>
      )}
    </div>
  );
};

export default BottomMenu;
