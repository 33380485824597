import React, { useState, useRef, useCallback } from "react";
import { useRequest, useInterval, useEventListener } from "ahooks";
import { Form, Input, Button, Checkbox, message, Space } from "antd";
import cookie from "react-cookies";
import cookies from "cookies";
import { Spin } from "antd";
import Loading from "@/components/Loading";
import styles from "./index.module.scss";
import { RouteComponentProps, Link } from "react-router-dom";
import axios from "axios";
import { getUserPermission, getverifyCode, login } from "@/services/user";
import userStore from "@/store/user";
import appMsg from "@/store/appmsg";

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [username, setusername] = useState<string>("");
  const [password, setpassword] = useState<string>("");

  const [codeValue, setcodeValue] = useState<string>("");
  const [codeKey, setcodeKey] = useState<string>("");
  let codeUrl = "";
  const { data: verifyCodedata } = useRequest(getverifyCode, {
    formatResult: (res) => res.data,
    onSuccess: (data) => {
      setcodeKey(data.codeKey);
    },
  });
  const { data, run: getverifycode } = useRequest(getverifyCode, {
    manual: true,
    formatResult: (res) => res.data,
    onSuccess: (data) => {
      setcodeKey(data.codeKey);
    },
  });
  codeUrl = data?.codeUrl;

  function handleSubmit() {
    history.push("/");
  }

  const onFinish = (values: any) => {
    console.log("Success:", values);
    setusername(values.username);
    setpassword(values.password);
    setcodeValue(values.codeValue);
    tologin();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let verifyCode = { codeKey: codeKey, codeValue: codeValue };

  // const { data: userInfoResult, run: byUserIdGetUserInfo } = useRequest(
  //   useCallback(
  //     () =>
  //       getUserPermission({
  //         username: username,
  //         password: password,
  //       }),
  //     [username, password, verifyCode]
  //   ),
  //   {
  //     manual: true,
  //     formatResult: (res) => {
  //       console.log(res);
  //       if (res.code === 200) {
  //         const userInfoData = res.data;
  //         // userStore.detailInfo = userInfoData;
  //         console.log(res);
  //       }
  //     },
  //   }
  // );

  const { data: loginData, run: tologin, loading } = useRequest(
    useCallback(() => {
      return login({
        username: username,
        password: password,
        verifyCode: verifyCode,
      });
    }, [username, password, verifyCode]),
    {
      manual: true,
      formatResult: async (res) => {
        console.log("formatResult=====", res);
        if (res.code == 200) {
          let returndata = res.data;
          localStorage.setItem("userName", returndata.name);
          localStorage.setItem("userId", returndata.id);
          localStorage.setItem("info", JSON.stringify(returndata));

          userStore.token = returndata.token;
          userStore.userName = returndata.userName;
          userStore.userId = returndata.id;
          userStore.info = returndata;


          // 请求用户权限
          const { data: permissionData } = await getUserPermission(
            returndata.token
          );
          console.log(permissionData);
          // 保存权限和角色数组
          userStore.platformRoleList = permissionData;

          // 判断有没有该平台的权限(根据权限判断能不能登录平台)
          if(permissionData.length){
            const findRoleResult = permissionData?.find(
              (item: any) => item.name.indexOf("静态交通可视化") >= 0
            );
            console.log(123)
            if (!findRoleResult) {
              message.error("该账号没有该平台权限!");
              getverifycode();
              return;
            } else {
              userStore.userMenus = findRoleResult.children;
              localStorage.setItem("userMenus", JSON.stringify(findRoleResult.children));
            }
          } else {
            // 超管
          }

          cookie.save("vue_admin_template_token", returndata.token, {
            path: "/",
          });
          history.push("/");
        } else {
          message.error(res.message);
          getverifycode();
        }
      },
    }
  );

  return (
    <div className={`${styles.login_contenter}`}>
      <div className={`${styles.login_box}`}>
        <div className={`${styles.login_name}`}>
          <p>南昌市湾里静态交通大数据可视化平台</p>
          <div className={`${styles.login_tag}`}>
            <span>科技改变生活 | 智能引导未来</span>
          </div>
        </div>
        <div className={`${styles.login_main}`}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="用户名"
              name="username"
              rules={[{ required: true, message: "请输入用户名!" }]}
            >
              <Input placeholder="请输入用户名!" />
            </Form.Item>

            <Form.Item
              className="password"
              label="密  码"
              name="password"
              rules={[{ required: true, message: "请输入密码 !" }]}
            >
              <Input.Password placeholder="请输入密码!" />
            </Form.Item>
            <div className={`${styles.verifybox}`}>
              <Form.Item
                label="验证码"
                name="codeValue"
                rules={[{ required: true, message: "请输入验证码!" }]}
              >
                <Input id="basic_verifyCode" placeholder="请输入验证码!" />
              </Form.Item>
              <span className={`${styles.verifyImg}`}>
                <img
                  src={codeUrl ? codeUrl : verifyCodedata?.codeUrl}
                  alt=""
                  title="点击换一张"
                  onClick={getverifycode}
                />
              </span>
            </div>

            {loading ? (
              <Button type="primary" htmlType="submit" className="loginbnt">
                <Spin /> 登陆
              </Button>
            ) : (
              <Button type="primary" htmlType="submit" className="loginbnt">
                登陆
              </Button>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
