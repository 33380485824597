/* eslint-disable jsx-a11y/iframe-has-title */

import React ,{useImperativeHandle,forwardRef,useState,useCallback}from 'react';
import { useRequest, useInterval } from 'ahooks';
import axios from 'axios'
import { Modal } from 'antd';
import styles from './index.module.scss';
import IframeModal from '@/components/IframeModal';
import qs from 'qs'
import cookie from 'react-cookies'

const Monitor:React.FC<{

// eslint-disable-next-line no-empty-pattern
}> = ({}) => {
  const[showiframeModal,setShowiframeModal]=useState(false)
  const[accessTokenModal,setaccessToken]=useState('')
  const[FulliframeModal,setFulliframeModal]=useState(false)
  // let getToken= localStorage.getItem('accessToken')||''
  // setaccessToken(getToken)
 const onshow=()=>{
  setShowiframeModal(true)
 }


 const getAccessToken=()=>{
     
        if(accessTokenModal){
             return
        }
         console.log(55555555)
        let appKey='fbdf758e83964f86904632c4a66b649f'
        let appSecret="65a69cbed4f7e5bc551eff54c260fd51"

        axios({
          method: "post",
          url: "https://open.ys7.com/api/lapp/token/get",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data:qs.stringify({appKey:appKey, appSecret:appSecret})
        }).then((res) => {
           setaccessToken(res.data.data.accessToken)
            localStorage.setItem("accessToken", res.data.data.accessToken);
          }).catch((err) => {});
      }
  if(showiframeModal){
    getAccessToken()
  }
  const hdliveUrllist=[
    {hdliveUrl:`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/G24307224/1.live&autoplay=1&accessToken=${accessTokenModal}`},
    {hdliveUrl:`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/G24307453/1.hd.live&autoplay=1&accessToken=${accessTokenModal}`},
    {hdliveUrl:`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/G20363403/1.hd.live&autoplay=1&accessToken=${accessTokenModal}`},
    {hdliveUrl:`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/G24307239/1.live&autoplay=1&accessToken=${accessTokenModal}`}
  ]
  var requestFullScreen =()=>{

         var de:any
         de = document.documentElement;//document.getElementsByTagName('iframe'); document.documentElement
         if (de.requestFullscreen) {
           de.requestFullscreen();
         } else if (de.mozRequestFullScreen) {
           de.mozRequestFullScreen();
         } else if (de.webkitRequestFullScreen) {
           de.webkitRequestFullScreen();
         }
     };
 let onshowiframe=()=>{
  //setFulliframeModal(true)
  requestFullScreen()
 }
  return (
    <>
    <div  className={styles.monitor_btn} onClick={onshow}>查看监控</div>
{
  showiframeModal?
    <Modal
    visible={showiframeModal}
    className={styles.alarm_modal}
    onCancel={() => setShowiframeModal(false)}
    width={1500}
    centered={false}
    maskClosable={false}
    footer={null}
    closeIcon={<img src="/img/icon_close.png" alt="" className={styles.alarm_close} />}
    maskStyle={{background: 'transparent'}}
  >
    <div className={styles.list}>
     <iframe onDoubleClick={onshowiframe} allowFullScreen src={'https://web.lzzhtc.com/cloud-table/#/videoSurveillance2?token='+cookie.load('vue_admin_template_token')+'&id='+localStorage.getItem('userId')} ></iframe>
  </div>
  </Modal>:null
}
  </>
  )
}

export default Monitor;